import { React, useContext, createContext, useState } from "react";
import fetchJsonp from "fetch-jsonp";

const INITIAL_STATE = {
  options: {
    locations: [],
    regions: [],
    categories: [],
    industries: [],
    postingTypes: [],
    jobTypes: [],
  },
  filters: {},
  keywords: "",
  autocomplete: "",
};

const FiltersContext = createContext();

export const useFiltersContext = () => {
  return useContext(FiltersContext);
};

const FiltersProvider = ({ children }) => {
  const [filtersState, setFiltersState] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(true);

  const removeDuplicates = (array) => {
    return array.filter((a, b) => array.indexOf(a) === b);
  };

  const onKeywordsChange = (e) => {
    e?.target &&
      setFiltersState({
        ...filtersState,
        keywords: e.target.value || "",
      });
  };

  const onLocationSelected = ({
    latitude,
    longitude,
    distance,
    displayName,
  }) => {
    setFiltersState({
      ...filtersState,
      autocomplete: displayName,
      filters: {
        ...filtersState.filters,
        latitude,
        longitude,
        distance,
      },
    });
  };

  const onLocationChange = (val) => {
    !val && filtersState.filters.latitude && filtersState.filters.longitude
      ? setFiltersState({
          ...filtersState,
          autocomplete: val,
          filters: {
            ...filtersState.filters,
            latitude: "",
            longitude: "",
          },
        })
      : setFiltersState({
          ...filtersState,
          autocomplete: val,
        });
  };

  const onSelectionChange = (e) => {
    e?.target &&
      setFiltersState({
        ...filtersState,
        filters: {
          ...filtersState.filters,
          [e.target.name]: e.target.value !== "All" ? e.target.value : "",
        },
      });
  };

  const fetchOptions = async (
    apiEndpoint,
    groupOrIdParam,
    apiKey,
    optionNames
  ) => {
    var updatedState = filtersState;
    for (const { func, name, key } of optionNames) {
      try {
        await fetchJsonp(
          apiEndpoint +
            "/CareerPage/" +
            func +
            "?" +
            groupOrIdParam +
            "=" +
            apiKey +
            "&region=All",
          {
            timeout: 15000,
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonList) {
            let temp = [];
            if (!name.includes("Posting")) temp.push("All " + name);
            updatedState = {
              ...updatedState,
              options: {
                ...updatedState.options,
                [key]: temp.concat(removeDuplicates(jsonList)),
              },
            };
          })
          .catch(function (ex) {
            setLoading(false);
            console.error(ex, "error");
          });
      } catch (error) {
        setLoading(false);
        console.error(error.message, "error");
      }
    }
    setFiltersState(updatedState);
  };

  const resetLocation = () => {
    setFiltersState({
      ...filtersState,
      autocomplete: "",
      filters: {
        ...filtersState.filters,
        latitude: "",
        longitude: "",
      },
    });
  };

  const resetFilters = () => {
    setFiltersState({
      ...filtersState,
      filters: {},
      keywords: "",
      autocomplete: "",
    });
  };

  return (
    <FiltersContext.Provider
      value={{
        filtersState,
        setFiltersState,
        loading,
        setLoading,
        fetchOptions,
        onKeywordsChange,
        onLocationSelected,
        onLocationChange,
        onSelectionChange,
        resetLocation,
        resetFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export default FiltersProvider;
